<template>
  <v-container class="mt-2">
    <validation-observer ref="observer" v-slot="{ invalid }">
      <v-card>
        <v-card-title>
          <div class="title font-weight-medium">
            {{ $t("contact_heading") }}
          </div>
        </v-card-title>
        <v-card-subtitle class="mb-0">
          <div class="subtitle-2 card-subtitle">
            {{ $t("contact_subheading") }}
          </div>
        </v-card-subtitle>
        <v-card-text class="mt-n3">
          <form @submit.prevent="submit">
            <v-row no-gutters>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="inquiryType"
                  rules="required"
                >
                  <v-radio-group
                    v-model="inquiryType"
                    required
                    :error-messages="errors"
                  >
                    <div class="mb-2 contact-radio-text">
                      {{ $t("contact_inquiry_type") }}
                    </div>
                    <v-radio
                      v-for="item in concerns"
                      :value="item.text"
                      :key="item.value"
                      color="#602BD0"
                    >
                      <template v-slot:label>
                        <div class="black--text contact-radio-text">
                          {{ item.text }}
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </validation-provider>
              </v-col>

              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="message"
                  rules="required"
                >
                  <v-textarea
                    v-model="message"
                    :error-messages="errors"
                    required
                    outlined
                    class="contact-radio-text"
                    :label="$t('contact_message')"
                    :placeholder="$t('contact_message')"
                  ></v-textarea>
                </validation-provider>
              </v-col>
              <v-col cols="12">
                <p class="contact-text-p">
                  お問い合わせへのご返答に使用する目的で、本アプリにご登録いただいている情報が、管理者に通知されます。
                </p>
              </v-col>
              <v-btn
                type="submit"
                :disabled="invalid"
                :loading="loading"
                block
                color="primary"
                >{{ $t("contact_send") }}</v-btn
              >
            </v-row>
          </form>
        </v-card-text>
      </v-card>
    </validation-observer>
  </v-container>
</template>

<script>
export default {
  name: "ContactUsForm",
  data() {
    return {
      loading: false,
      message: "",
      inquiryType: "",
      concerns: [
        {
          value: "concern1",
          text: this.$t("contact_concern1")
        },
        {
          value: "concern2",
          text: this.$t("contact_concern2")
        },
        {
          value: "concern3",
          text: this.$t("contact_concern3")
        },
        {
          value: "concern4",
          text: this.$t("contact_concern4")
        },
        {
          value: "concern5",
          text: this.$t("contact_concern5")
        },
        {
          value: "concern6",
          text: this.$t("contact_concern6")
        },
        {
          value: "concern7",
          text: this.$t("contact_concern7")
        }
      ]
    };
  },
  methods: {
    submit() {
      this.$refs.observer.validate();

      this.loading = true;
      let data = {
        type: this.inquiryType,
        message: this.message
      };

      this.$store
        .dispatch("CONTACT_SUBMIT", data)
        .then(() => {
          this.$store.commit("showAlert", {
            text: this.$t("contact_success_message"),
            successStatus: true
          });
          this.message = "";
          this.inquiryType = "";
          this.$refs.observer.reset();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="css" scoped>
.contact-text-p {
  color: #a9a9a9;
  margin: -15px 0 15px;
  font-size: 13px;
}
.contact-radio-text {
  font-size: 14px;
}
.card-subtitle {
  font-size: 13px;
  color: #a9a9a9 !important;
}
.theme--light.v-input textarea {
  color: #a9a9a9;
}
</style>
